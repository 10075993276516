<template>
  <form
    class="login__container"
    action="javascript:void(0)"
    @submit="submitMethod()"
  >
    <h2 class="auth__title">Connexion</h2>

    <span>Etat du serveur {{this.showD}}</span>
    <span style="margin-left : 10px;">version {{this.version}}</span>
    <span>
    <span class="moreAccess" @click="moreAccess = !moreAccess">+</span></span>
    <br>
    <span class="badVersion" v-if="this.versionAlert">Vous n'avez pas les dernières mises à jour, veuillez vider le cache</span>

    <div class="form-row">
      <!-- Email -->
      <input
        aria-label="entrez votre identifiant"
        type="text"
        name="username"
        required
        v-model="username"
        class="form-row__input"
        placeholder="identifiant"
        autocomplete=""
        pattern="[A-Za-z0-9]{4,16}"
      />
    </div>

    <div class="form-row relative">
      <!-- Password -->
      <input
        aria-label="entrez votre mot de passe"
        v-model="password"
        class="form-row__input"
        :type="visibility"
        placeholder="Mot de passe"
        autocomplete=""
        min="8"
        required
        pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
      />
      <!--
      <div class="show__hide__password__container">
        <-- show the password --
        <a
          @click="showPassword()"
          class="show__hide__password"
          v-if="visibility == 'password'"
        >
          <img
            class="show__hide__logo"
            src="../assets/closed-eyes.svg"
            alt="Cacher le mot de passe"
          />
        </a>
        <-- hide the password --
        <a
          @click="hidePassword()"
          class="show__hide__password"
          v-if="visibility == 'text'"
        >
          <img
            class="show__hide__logo"
            src="../assets/vision.svg"
            alt="afficher le mot de passe"
        /></a>
      </div> -->
    </div>


     <div v-if="moreAccess" class="form-row">
      <!-- Email -->
      <input
        aria-label="Clé administrateur"
        type="text"
        name="Clé administrateur"
        v-model="_2_fa"
        class="form-row__input"
        placeholder="Clé administrateur"
        autocomplete=""
      />
    </div>

    <div class="form-row">
      <!-- connexion button -->
      <input
        aria-label="cliquez pour vous connecter"
        class="button grey_btn fa fa-input"
        id="validation"
        type="submit"
        value="&#xf2f6;  Valider"
      />
    </div>

    
   
    
  </form>
</template>

<script>
import { mapState } from "vuex";

const myAppPath = require('./../switchUrl');
const appPath = myAppPath.appPath;

export default {
  name: "Login",
  data: function() {
    return {
      visibility: "password",
      username: "",
      name: "",
      familly_name: "",
      password: "",
      showD : '⌛',
      version : '1.18',
      versionAlert : false,
      _2_fa : '',
      moreAccess : false,
    };
  },
  mounted() {
    
    this.getD();
  },
 /* computed: {
    //STORE
    ...mapState({

    }),
  },*/

  methods: {
    // SUBMIT FORM ------------------------------------------------
    submitMethod() {
      this.loginUser();
    },
    // CONNEXION -------------------------------------------------
    async fetchLogin() {
      if (
        this.password.includes("<" || ">" || "/" || "\\" || " " || "$" || "=")
      ) {
        alert(
          "les carractères suivants (et les espaces) ne sont pas autorisés : < > / \\ $ ="
        );
        return;
      }
      if (!(this.password === "") && !(this.username === "")) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            _type : 'connect',
            username: this.username,
            password: this.password,
            reinforced :this._2_fa
          }),
        };
        let response = await fetch(
          appPath+"/auth/login",
          requestOptions
        );
        if (!response.ok) {
          alert(
            "une erreur innattendue s'est produite, veuillez vérifier que vos données d'identification sont corrects"
          );
          // UNE MANIERE DE RECUPERER L ERREUR JSON 
          //response.text().then(text => { alert(text) })

          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        return await response.json();
      } //si un champ est resté vide on ne passe pas dans fetch
      else {
        alert("veuillez remplir tous les champs");
      }
    },

    loginUser() {
      // FETCH
      this.fetchLogin()
        .then((data) => {
          //console.log(data)
          
          if(data["data"][2]){
            localStorage.setItem("t_key",data["data"][2])
            //console.log('data[qrCode]');
            //console.log(data["data"][2]);
          }

          //console.log(data);
          //console.log(data["token"]);
          //bcrypt compare va renvoyer true ou false.
          if (data["data"][0] === true) {
            this.storeSendLogin(
              data["data"][1].username,
              data["data"][1].name,
              data["data"][1].familly_name,
              data["data"][1].id,
              data["data"][1].admin,
              data["data"][1].super,
              data["token"]
            );
          }
          
        })
        .catch((e) => console.log(e));
    },

    // SPEAK WITH STORE WHEN CONNEXION ACCEPTED BY SERVER------------------------------------

    storeSendLogin(username, name, familly_name, id, a5e789410fd,kiy656dgr28e, token) {
      this.$store.dispatch("login", {
        username: username,
        name: name,
        familly_name: familly_name,
        id_user: id,
        a5e789410fd: a5e789410fd,
        kiy656dgr28e :kiy656dgr28e,
        token: token,
      });
      this.$emit("connect");
    },
    //FUNCTIONS ---------------------------------------
    showPassword() {
      this.visibility = "text";
    },
    hidePassword() {
      this.visibility = "password";
    },
    getD(){
  
    this.fetchgetD()
        .then((data) => {
          //console.log("data");
          //console.log(data);
          if(data['data'][0]['version_nb'] != this.version){
            this.versionAlert = true;
          }
          /*this.newUserMessage = true;
          setTimeout(
            function() {
              this.cleanSuccesMessage();
            }.bind(this),
            1000
          );*/
          if (data["data"]) {
              //console.log('getD');
              //console.log(data["data"]);
              this.showD = '✅';
          } else {
            this.showD = '❌';
          }
        })
        .catch((e) => console.log(e));
    },
    // FETCH 
    async fetchgetD() {
  let response = await fetch(appPath+"/admin_order/get_auth_data", {
    method: "GET"
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
    },

  },
};
</script>

<style scoped>
.login__container {
  max-width: 100%;
  width: 540px;
  height: auto;
  font-size: 20px;
  background: #ee821a;
  border-radius: 16px;
  padding: 32px;
  padding-bottom: 10px;
  -webkit-box-shadow: 5px 5px 15px 5px #000000; 
  box-shadow: 5px 5px 15px 5px #000000;
}

.auth__title {
  text-align: center;
  font-weight: 800;
  color: #0d444b;
}

.button {
  /*color: black; */
  border: 3px transparent solid;
  border-radius: 8px;
  font-weight: 700;
  font-size: 22px;
  width: 100%;
  padding: 10px;
  color: #0d444b;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.button:active {
  transform: scale(1.1);
	transition: .1s;
}
.button:hover {
  background-color: white;
}

.form-row {
  display: flex;
  margin: 25px 0px;
  gap: 16px;
  flex-wrap: wrap;
}

.form-row__input {
  padding: 8px;
  border: none;
  border-radius: 8px;
  background: #f2f2f2;
  font-weight: 500;
  font-size: 25px;
  flex: 1;
  min-width: 100px;
  color: black;
}

.form-row__input::placeholder {
  color: #aaaaaa;
}
.relative {
  position: relative;
}
.show__hide__password__container {
  position: absolute;
  pointer-events: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.show__hide__password {
  pointer-events: visible;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
}
.show__hide__logo {
  position: absolute;
  height: 55%;
  cursor: pointer;
}
.badVersion{
  font-size: 16px;
  color: yellow;
}
.moreAccess {
  border: 1px solid black;
  padding : 0px 4px;
  margin-left: 5px;
  font-size: 20px;
  border-radius:100px;
  cursor: pointer;
  
}
@media screen and (max-width: 1100px) {
  .login__container {
    width: 300px;
   /*height: 270px;*/
    font-size: 15px;
    padding: 16px;
  }
  form > h2 {
  margin-top : 10px;
}

  .button {
    font-size: 15px;
  }

  .form-row {
  margin: 16px 0px;
}

  .form-row__input {
    font-size: 16px;
  }
  .badVersion{
  font-size: 13px;
}
}
</style>
