<template>
  <div class="auth_page">

    <div class="presentation">
   
     <h1 id="logo__title"> 
       <img alt="DIGITECH TELECOMS" src="../assets/Digitech.png" /></h1>
        <img alt="Cable de fibre optique" src="../assets/fibre.jpg" />
    </div>

    <Login ></Login>
  </div>
</template>

<script>
// @ is an alias to /src
import Login from "@/components/Login.vue";
import { mapState } from "vuex";

export default {
  name: "Auth",
  components: {
    Login,
  },
  data() {
    return {

    };
  },
  // VIDER LE STORAGE EN ARRIVANT SUR CETTE PAGE 
  mounted() {
    
    //console.log("AUTH MOUNTED");
    this.$store.commit("isDisconnected");
    this.$store.state.usingMode = false;
    this.$store.dispatch('resetCartState');
    //console.log(this.$store.state.user);
    localStorage.clear();
    sessionStorage.clear();
    //console.log("STORAGE CLEARED");
    clearInterval(this.$store.state.interval0);
    this.$store.state.interval0 = null;
  },
  //LE STORE
  computed: {
    ...mapState({
      user: "userConnectedInfos",
      newuser: "newuser",
      
    }),
  },
};
</script>
<style scoped>
.auth_page {
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  align-items: center;
}
.presentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 20px 0;
  font-weight: 600;
}
img {
  max-width: 30%;
}
#logo__title {
  font-size: 27px;
}


@media screen and (max-width: 1100px) {
  .auth_page {
    margin-top: 0px;
  }
  img {
  max-width: 80%;
}

  #logo__title {
    font-size: 19px;
  }
}
</style>
