import { createStore } from 'vuex'
import CryptoJS from "crypto-js";
import router from '../router/index'
const key = "82f2ceed4c503896c8a291e560bd4325"; // change to your key
const iv = "sinasinasisinaaa"; // change to your iv


const myAppPath = require('./../switchUrl');
const appPath = myAppPath.appPath;

//"+appPath+"
//yrtjdqf.cluster024.hosting.ovh.net

function aesEncrypt(txt) {
  const cipher = CryptoJS.AES.encrypt(txt, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
  });

  return cipher.toString();
}
function aesDencrypt(txt) {
  const cipher = CryptoJS.AES.decrypt(txt, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
  });

  return CryptoJS.enc.Utf8.stringify(cipher).toString();
}
//var tester = aesEncrypt("blabla");
//console.log("encrypt :", tester);
//console.log("decrypt :", aesDencrypt(tester));

const getDefaultState = () => {
  return {
    userConnectedInfos: {
      id_user: "0",
      username : "",
      name: "",
      familly_name: "",
      password: "",
      a5e789410fd: 0,
      token: null,
    },
    userExist: {
      username : null,
    },
    isConnected : false,
    usingMode : false,
    allUsers: [],
    itemConsumables : {},
    itemTools : {},
    itemCables : {},
    itemClients : {},
    itemConsumablesAndDetails : {},
    itemToolsAndDetails : {},
    itemCablesAndDetails : {},
    itemClientsAndDetails : {},
    itemConsumablesByLocation : {},
    itemToolsByLocation : {},
    itemCablesByLocation : {},
    itemClientsByLocation : {},
    itemInBasket : {},
    myOrdersList : {},
    myOrdersListExtended : {},
    myBookingsList : {},
    myReelsList : {},
    borrowMan : {},
    requestResponse : false,
    succes01 : false,
    succes02 : false,
    succes03 : false,
    succes04 : false,
    succes05 : false,
    error01 : false,
    errorRef : 'error',
    basketResponse : false,
    toolsLimitUser : {},
    myStockAlerts : [],
    myCablesAlerts  : [],
    myEmptyCables  : [],
    myClientsAlerts : [],
    myReceptions : [],
    myRemainders : [],
    allAddresses : [],
    adminBasketInfos : [],
    globalHistory : [],
    receptionHistory : [],
    userHistory : [],
    adminChangesHistory : [],
    bookingHistory : [],
    bookingInProgress : [],
    reelsHistory : [],
    reelsMovement : [],
    toolsHistory : [],
    intervalDate : [],
    intervalDateActivities : [],
    allImages : [],
    usersIds : [],
    dataFetched : false,
    dataFetched2 : false,
    activityResponse : false,
    constructionResponse : false,
    constructionStatusResponse : false,
    allConstructions : {},
    allActivities : {},
    receptionClient : false,
    receptionClientStock : false,
    receptionClientERROR : false,
    receptionClientStockERROR : false,
    allAssignements : false,
    requestEnd : false,
    inProgressConstructions : {},
    constructionResumeResponse : false,
    constructionsHistory : [],
    constructionsStatusHistory : [],
    assignementHistory : [],
    constructionsResumeHistory : [],
    constructionsResumeHistoryGlobal : [],
    constructionsResumeAdmin : [],
    activitiesHistory : [],
    waitingRequest :  true,
    waitingRequest2 : false,
    servicesClients : [],
    clientFromServices : [],
    clientFromConstructions : [],
    documentsInfos : [],
    documentsData : [],
    exitUsingMode : false,
    t_Key : 'unlocked',
    allLocations : [],
  }
}

// initial state
const state = getDefaultState()

export default createStore({
  state,
  mutations: {
    resetState (state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState())
    },
    // --------------USERS MUTATIONS
    allUsers(state, data) {
      state.allUsers = [];
      //console.log(data);
      for (let i = 0; i < data.length; ++i) {
        //console.log(i);
        state.allUsers.push(data[i]);
      }
      },
      usersIds(state, data) {
        state.usersIds = data;
        //console.log(data);
      },
    userExist(state, data) {
      state.userExist.username = data.username;
    },
    t_Key(state, data) {
      state.t_Key = data;
    },
    isConnected(state) {
      state.isConnected = true;
      //console.log('IS CONNECTED STORE TRUE');
    },
    isDisconnected(state) {
      state.isConnected = false;
      //console.log('IS CONNECTED STORE FALSE');
    },
    connectUser: function (state, userInfos) {
      state.userConnectedInfos.username = userInfos.username;
      state.userConnectedInfos.name = userInfos.name;
      state.userConnectedInfos.familly_name = userInfos.familly_name;
      state.userConnectedInfos.id_user = userInfos.id_user;
      state.userConnectedInfos.a5e789410fd = userInfos.a5e789410fd;
      state.userConnectedInfos.token = userInfos.token;
    },
    useMode(state,usingMInfos) {
      //console.log('store request use mode');
      localStorage.setItem('usingModeU',usingMInfos.username);
      localStorage.setItem('usingModeI',usingMInfos.id);
      localStorage.setItem('usingModeN',usingMInfos.name);
      state.usingMode = true;
      //console.log('IS CONNECTED STORE TRUE');
    },
    exitUseMode(state,bool){
      //console.log(bool);
      state.exitUsingMode = bool;
    },

    //-----------------ITEMS MUTATION
    itemConsumables(state, data){
      state.itemConsumables = data;
    },
    itemTools(state, data){
      state.itemTools = data;
    },
    itemCables(state, data){
      state.itemCables = data;
    },
    itemClients(state, data){
      state.itemClients = data;
    },

        //-----------------ITEMS MUTATION
    itemConsumablesAndDetails(state, data){
      state.itemConsumablesAndDetails = data;
    },
    itemToolsAndDetails(state, data){
      state.itemToolsAndDetails = data;
    },
    itemCablesAndDetails(state, data){
      state.itemCablesAndDetails = data;
    },
    itemClientsAndDetails(state, data){
      state.itemClientsAndDetails = data;
    },
    dataFetched(state, data){
      state.dataFetched = true;
    },
    dataFetched2(state, data){
      state.dataFetched2 = true;
    },


            //-----------------ITEMS MUTATION
    itemConsumablesByLocation(state, data){
      // faire de la location une key
      state.itemConsumablesByLocation[data.location] = data;
      // ne pas conserver l'entrée location dans l'objet puisque c'est une key
      delete state.itemConsumablesByLocation[data.location].location
    },
    itemToolsByLocation(state, data){
      //console.log(data);
      // faire de la location une key
      state.itemToolsByLocation[data.location] = data;
      // ne pas conserver l'entrée location dans l'objet puisque c'est une key
      delete state.itemToolsByLocation[data.location].location
    },
    itemCablesByLocation(state, data){
      // faire de la location une key
      state.itemCablesByLocation[data.location] = data;
      // ne pas conserver l'entrée location dans l'objet puisque c'est une key
      delete state.itemCablesByLocation[data.location].location
    },
    itemClientsByLocation(state, data){
      // faire de la location une key
      state.itemClientsByLocation[data.location] = data;
      // ne pas conserver l'entrée location dans l'objet puisque c'est une key
      delete state.itemClientsByLocation[data.location].location
    },
    allLocations(state, data){
      state.allLocations = data;   
    },

    // All Images
    allImages(state, data){
      state.allImages = data;   
    },

    itemInBasket(state, data){
      const location = JSON.parse(JSON.stringify(data.location));
      // ne pas conserver l'entrée location dans l'objet puisque c'est une key
      delete data.location;
      //console.log(location);
      // faire de la location une key
      state.itemInBasket[location] = data;
      
      //console.log('store data');
      //console.log(data);
      state.basketResponse = true;
      setTimeout(() => {
        state.basketResponse = false;
      }, 2000);
    },

    //-----------------ORDERS MUTATION
    myOrders(state, data){
      state.myOrdersList = data;
    },
    myOrdersExtended(state, data){
      state.myOrdersListExtended = data;
    },
    myBookings(state, data){
      state.myBookingsList = data;
    },
    myReels(state, data){
      state.myReelsList = data;
    },

    // REQUEST RESPONSE
    requestResponse(state){
      state.requestResponse = true;
    },

    // REQUEST RESPONSE
    errorResponse(state){
      state.error01 = true;
    },

    // REQUEST RESPONSE
    errorRef(state,data){
      state.errorRef = data;
    },

    // REQUEST RESPONSE
    succesResponse(state,data){
      switch (data) {
          case 'ReturnBorrow':
            state.succes01 = true;
            setTimeout(() => {
              state.succes01 = false;
            }, 2000);
          break;
          case 'Annulation':
            state.succes02 = true;
            setTimeout(() => {
              state.succes02 = false;
            }, 2000);
          break;
          case 'Confirmation':
            state.succes03 = true;
            setTimeout(() => {
              state.succes03 = false;
            }, 2000);
          break;
          case 'Reservation':
            state.succes04 = true;
            setTimeout(() => {
              state.succes04 = false;
            }, 2000);
          break;
          case 'Retour':
            state.succes05 = true;
            setTimeout(() => {
              state.succes05 = false;
            }, 2000);
          break;
      
        default:
          break;
      }
      
    },

    // show Borrow
    showBorrow(state, data){
      state.borrowMan[data.item_id] = {
        reel : data.username
    }},
      //tools Limit
    toolsLimit(state, data){
      //re-init
      state.toolsLimitUser = {};
      for(let i in data){
        state.toolsLimitUser[data[i].ref] = {
          date : data[i]["MAX(date)"]
        }
      }     
    },

    //------- ALERTS RESPONSES
    myAlerts(state, data){
        state.myStockAlerts = data;   
    },
    adminBasketInfos(state, data){
      state.adminBasketInfos = data;   
  },
    myCableAlerts(state, data){
      state.myCablesAlerts = data;   
    },
    myEmptyCables(state, data){
      state.myEmptyCables = data;   
    },
    myClientsAlerts(state, data){
      state.myClientsAlerts = data;   
    },

    //------- ADMIN RECEPTIONS RESPONSES
    myReceptions(state, data){
      state.myReceptions = data;   
    },
    myRemainders(state, data){
      state.myRemainders = data;   
    },

    // ADDRESSES
    allAddresses(state, data){
      state.allAddresses = data;   
    },

    //-------------PUR HISTORY PAGE -----------

    globalHistory(state, data){
      state.globalHistory = data;
    },
    receptionHistory(state, data){
      state.receptionHistory = data;
    },
    userHistory(state, data){
      state.userHistory = data;
    },

    adminChangesHistory(state, data){
      state.adminChangesHistory = data;
    },

    bookingHistory(state, data){
      state.bookingHistory = data;
    },

    bookingInProgress(state, data){
      state.bookingInProgress = data;
    },

    reelsHistory(state, data){
      state.reelsHistory = data;
    },

    reelsMovement(state, data){
      state.reelsMovement = data;
    },

    toolsHistory(state, data){
      state.toolsHistory = data;
    },

    intervalDate(state, data){
      state.intervalDate = data;
    },
    
    //---------------- COMMIT ACTIVITES -------------
    activityResponse(state, data){
      state.activityResponse = data;
    },
    requestEnd(state, data){
      state.requestEnd = data;
    },
    constructionResponse(state, data){
      state.constructionResponse = data;
    },
    constructionStatusResponse(state, data){
      state.constructionStatusResponse = data;
    },
    allActivites(state, data){
      state.allActivities = data;
    },
    allConstructions(state, data){
      state.allConstructions = data;
    },
    receptionClient(state, data){
      //console.log(data);
      state.receptionClient = data;
    },
    receptionClientERROR(state, data){
      state.receptionClientERROR = data;
    },
    receptionClientStock(state, data){
      state.receptionClientStock = data;
    },
    receptionClientStockERROR(state, data){
      state.receptionClientStockERROR = data;
    },
    allAssignements(state,data){
      state.allAssignements = data;
    },
    inProgressConstructions(state,data){
      state.inProgressConstructions = data;
    },
    constructionResumeResponse(state,data){
      state.constructionResumeResponse = data;
    },
    constructionsHistory(state, data){
      state.constructionsHistory = data;
    },
    constructionsStatusHistory(state, data){
      state.constructionsStatusHistory = data;
    },
    assignementHistory(state, data){
      state.assignementHistory = data;
    },
    intervalDateActivities(state, data){
      state.intervalDateActivities = data;
    },
    constructionsResumeHistory(state, data){
      state.constructionsResumeHistory = data;
      state.waitingRequest = false;
    },
    constructionsResumeHistoryGlobal(state, data){
      state.constructionsResumeHistoryGlobal = data;
      state.waitingRequest2 = false;
    },
    constructionsResumeAdmin(state,data){
      state.constructionsResumeAdmin = data;
    },
    activitiesHistory(state,data){
      state.activitiesHistory = data;
    },
    // SERVICES -------
    servicesClients(state,data){
      state.servicesClients = data;
    },
    clientFromServices(state,data){
      state.clientFromServices = data;
    },
    clientFromConstructions(state,data){
      state.clientFromConstructions = data;
    },
    documentsInfos(state,data){
      state.documentsInfos = data;
    },
    documentsData(state,data){
      state.documentsData = data;
    },


  },
  actions: {
    resetCartState ({ commit }) {
      commit('resetState')
    },

/* ---------------------USERS-----------------------------*/
// LOGIN USER ------------------
    login: ({ commit }, userInfos) => {

      //console.log("l'utilisateur est connecté");
      //console.log(userInfos);
      if(userInfos.kiy656dgr28e === 1){
        //console.log('super');
        userInfos.kiy656dgr28e = 'gdf2g5dhy7'+userInfos.token.substring(21,58);
      }
      else {
        //console.log('not super');
        userInfos.kiy656dgr28e = Math.random().toString(36)+Math.random().toString(36);
      }
      /*générer des chaines aléatoires de verification pour garantir le bon acces front end
      même si un utilisateur trafic le local ou sessionStorage */
      var lockString =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
        const rd = '2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16'+userInfos.token+'1202'+userInfos.token.substr(12, 42);
      if (userInfos.a5e789410fd === 1) {
        userInfos.a5e789410fd = rd;
      }else{
        userInfos.a5e789410fd = Math.random().toString(36)+Math.random().toString(36);
      };
        const lockStringCrypted = aesEncrypt(lockString);
        //console.log("basic :", lockString);
        //console.log("encrypted :", lockStringCrypted);
        localStorage.setItem("LSSECKOCAC", JSON.stringify(lockString));
        localStorage.setItem(
          "LSSECKOCACCrypted",
          JSON.stringify(lockStringCrypted)
        );
      sessionStorage.setItem("currentUser", JSON.stringify(userInfos.name));
      localStorage.setItem("connectedUser", JSON.stringify(userInfos));
      localStorage.setItem("token", JSON.stringify(userInfos.token));
      commit("connectUser", userInfos);
      //commit('isConnected');
      if (userInfos.a5e789410fd === rd){
        router.push("/AddItems");
      }
      else {
        router.push("/Items");
      }
    },

    // GET ALL USERS ------------------
    getAllUsers: ({ commit, dispatch }, id) => {
      dispatch("fetchGetAllUsers", id)
        .then((data) => {
          //console.log(data);
          commit("allUsers", data["data"]);
        })
        .catch((e) => console.log(e));
    },
    // FETCH ALL USERS ----------------------------------------------
    async fetchGetAllUsers({ commit, dispatch }, id) {
      let response = await fetch(appPath+"/auth/allUsers/" + id, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },
         // GET ONE USER ------------------
        // l'id utilisateur indispensable pour passer l'authentification
        getOneUser: ({ commit, dispatch }, ids) => {
          dispatch("fetchGetOneUser", ids)
            .then((data) => {
              //console.log(data["data"][0]);
              commit("userExist", data["data"][0]);
            })
            .catch((e) => console.log(e));
        },
        // FETCH ONE USER ----------------------------------------------
        async fetchGetOneUser({ commit, dispatch }, ids) {
          //console.log(id);
          let response = await fetch(appPath+"/auth/oneUser/" + ids, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          });
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            //console.log('not response ok, error : ' + error);
            alert("une erreur innattendue s'est produite");
            return Promise.reject(error);
          }
          return await response.json();
        },
    
        // GET tipmer access ------------------
        getTimerAccess: ({ commit, dispatch }, ids) => {
          dispatch("fetchGetTimerAccess", ids)
            .then((data) => {
              //console.log(data["data"][0]);
              commit("t_Key", data["data"][0]);
            })
            .catch((e) => console.log(e));
        },

        // FETCH ONE USER ----------------------------------------------
        async fetchGetTimerAccess({ commit, dispatch }, ids) {
          //console.log(id);
          let response = await fetch(appPath+"/auth/timer_access/" + ids, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          });
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            //console.log('not response ok, error : ' + error);
            alert("une erreur innattendue s'est produite");
            return Promise.reject(error);
          }
          return await response.json();
        },
    
       

/* ------------ITEMS ---------------------------*/
/*trashItemInBasket: ({ commit }, data) => {
  commit("trashItemInBasket", data);
},*/

 // GET ALL ITEMS + DETAILS ------------------
 getAllItemsAndDetails: ({ commit, dispatch }, id) => {
  dispatch("fetchGetConsumablesAndDetails", id)
    .then((data) => {
      //console.log(data);
      commit("itemConsumablesAndDetails", data["data"]);
    })
    .catch((e) => console.log(e));
    
    dispatch("fetchGetToolsAndDetails", id)
    .then((data) => {
      //console.log(data);
      commit("itemToolsAndDetails", data["data"]);
    })
    .catch((e) => console.log(e));
    
    dispatch("fetchGetCablesAndDetails", id)
    .then((data) => {
      //console.log(data);
      commit("itemCablesAndDetails", data["data"]);
    })
    .catch((e) => console.log(e));

    dispatch("fetchGetClientsAndDetails", id)
    .then((data) => {
      //console.log(data);
      commit("itemClientsAndDetails", data["data"]);
      commit("dataFetched");
    })
    .catch((e) => console.log(e));
},

 // GET ALL ITEMS ------------------
 getAllItems: ({ commit, dispatch }, id) => {
  dispatch("fetchGetConsumables", id)
    .then((data) => {
      //console.log('FETCH TEST');
      //console.log(data);
      commit("itemConsumables", data["data"]);
    })
    .catch((e) => console.log(e));
    
    dispatch("fetchGetTools", id)
    .then((data) => {
      //console.log(data);
      commit("itemTools", data["data"]);
    })
    .catch((e) => console.log(e));
    
    dispatch("fetchGetCables", id)
    .then((data) => {
      //console.log(data);
      commit("itemCables", data["data"]);
    })
    .catch((e) => console.log(e));

    dispatch("fetchGetClients", id)
    .then((data) => {
      
      commit("itemClients", data["data"]);
    })
    .catch((e) => console.log(e));
},

// FETCH CONSUMABLES----------------------------------------------
async fetchGetConsumables({ commit, dispatch }, id) {
  let response = await fetch(appPath+"/items/consumables/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
// FETCH TOOLS----------------------------------------------
async fetchGetTools({ commit, dispatch }, id) {
  let response = await fetch(appPath+"/items/tools/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
// FETCH CABLES----------------------------------------------
async fetchGetCables({ commit, dispatch }, id) {
  let response = await fetch(appPath+"/items/cables/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
// FETCH CLIENTS----------------------------------------------
async fetchGetClients({ commit, dispatch }, id) {
  let response = await fetch(appPath+"/items/clients/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},

// FETCH CONSUMABLES DETAILS----------------------------------------------
async fetchGetConsumablesAndDetails({ commit, dispatch }, id) {
  let response = await fetch(appPath+"/items/consumables_details/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
// FETCH TOOLS DETAILS----------------------------------------------
async fetchGetToolsAndDetails({ commit, dispatch }, id) {
  let response = await fetch(appPath+"/items/tools_details/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
// FETCH CABLES DETAILS----------------------------------------------
async fetchGetCablesAndDetails({ commit, dispatch }, id) {
  let response = await fetch(appPath+"/items/cables_details/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
// FETCH CLIENTS DETAILS----------------------------------------------
async fetchGetClientsAndDetails({ commit, dispatch }, id) {
  let response = await fetch(appPath+"/items/clients_details/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},

 // GET ALL ITEMS LOCATION ------------------
 getAllItemsByLocation: ({ commit, dispatch }, locationAndId) => {
  dispatch("fetchGetConsumablesByLocation", locationAndId)
    .then((data) => {
      const location = locationAndId.split("_")[0];
      //console.log(data);
      data["data"].location = location;
      //console.log(data["data"].location);
      commit("itemConsumablesByLocation", data["data"]);
    })
    .catch((e) => console.log(e));
    
    dispatch("fetchGetToolsByLocation", locationAndId)
    .then((data) => {
      const location = locationAndId.split("_")[0];
      //console.log(data);
      data["data"].location = location;
      commit("itemToolsByLocation", data["data"]);
    })
    .catch((e) => console.log(e));
    
    dispatch("fetchGetCablesByLocation", locationAndId)
    .then((data) => {
      const location = locationAndId.split("_")[0];
      //console.log(data);
      data["data"].location = location;
      commit("itemCablesByLocation", data["data"]);
    })
    .catch((e) => console.log(e));

    dispatch("fetchGetClientsByLocation", locationAndId)
    .then((data) => {
      const location = locationAndId.split("_")[0];
      //console.log(data);
      data["data"].location = location;
      commit("itemClientsByLocation", data["data"]);
    })
    .catch((e) => console.log(e));
},
 // GET ALL ITEMS LOCATION ------------------
 getAllLocations: ({ commit, dispatch }, Id) => {
  dispatch("fetchGetAllLocations", Id)
    .then((data) => {
      //console.log(data["data"].location);
      commit("allLocations", data["data"]);
    })
    .catch((e) => console.log(e));
  },

// FETCH CONSUMABLES LOCATION----------------------------------------------
async fetchGetConsumablesByLocation({ commit, dispatch }, locationAndId) {
  let response = await fetch(appPath+"/items/consumables_location/" + locationAndId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
// FETCH TOOLS LOCATION----------------------------------------------
async fetchGetToolsByLocation({ commit, dispatch }, locationAndId) {
  let response = await fetch(appPath+"/items/tools_location/" + locationAndId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
// FETCH CABLES LOCATION----------------------------------------------
async fetchGetCablesByLocation({ commit, dispatch }, locationAndId) {
  let response = await fetch(appPath+"/items/cables_location/" + locationAndId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
// FETCH CLIENTS LOCATION----------------------------------------------
async fetchGetClientsByLocation({ commit, dispatch }, locationAndId) {
  let response = await fetch(appPath+"/items/clients_location/" + locationAndId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
// FETCH ALL LOCATIONS----------------------------------------------
async fetchGetAllLocations({ commit, dispatch }, Id) {
  let response = await fetch(appPath+"/items/all_locations/" + Id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},

    // GET ALL Images ------------------
    getAllImages: ({ commit, dispatch }, id) => {
      dispatch("fetchGetAllImages", id)
        .then((data) => {
          //console.log(data);
          commit("allImages", data["data"]);
        })
        .catch((e) => console.log(e));
    },
    // FETCH ALL USERS ----------------------------------------------
    async fetchGetAllImages({ commit, dispatch }, id) {
      let response = await fetch(appPath+"/items/all_images/" + id, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },

//getAllItemsInBasket
getItemsInBasket: ({ commit, dispatch }, orderBasket) => {
  dispatch("fetchGetItemInBasket", orderBasket)
    .then((data) => {
      const location = Object.keys(orderBasket)[0];
      //console.log(location);
      data["data"].location = location;
      //console.log(data["data"].location);
      //console.log(data["data"].location);
      commit("itemInBasket", data["data"]);
    })
    .catch((e) => console.log(e));
},
// FETCH CONSUMABLES LOCATION----------------------------------------------
async fetchGetItemInBasket({ commit, dispatch }, orderBasket) {
  //console.log(orderBasket);
 
  const requestOptions = {
          method: "POST",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            obj: orderBasket,
            user_id: orderBasket["id"],
          }),
        };

  let response = await fetch(
    appPath+"/items/basket_items",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
// PUT/POST ORDERS
userOrder: ({ commit, dispatch }, basketData) => {
  switch (basketData.action) {
    case 'Withdraw':
       //console.log('retirer');
       dispatch("fetchWithdrawOrder", basketData)
    .then((data) => {
      if(data['data']){
        if(data['data'].includes('ERREUR')){
          commit('errorResponse');
          if(!data['data'][0].includes('quantité') && !data['data'][1]){
            alert(data['data']);
          }
          if(data['data'][1] && !data['data'][1].includes('quantité')){
            if(data['data'][2]){
              commit('errorRef', data['data'][2]);
            }
            alert(data['data']);
          }
          //location.reload()
          return
        };
        commit("succesResponse",'Confirmation');
        if(Object.values(basketData.limitTool).length != 0){
          dispatch("fetchLimitTool", basketData)
    .then((data) => {
      if(data['data']){
        alert('Rappel : vous avez pris un/des outils hors limite...')
      }
      else {
        alert('Une erreur minime a été detectée sur l\'historique tool_alert')
      }
    }).catch((e) => console.log(e));
     }}
      else{
        commit("errorResponse");
        location.reload()
      }
      //commit("itemInBasket", data["data"]);
    })
    .catch((e) => console.log(e));
        break;
    case 'Book':
       //console.log('reserver');
       dispatch("fetchBookOrder", basketData)
    .then((data) => {
      if(data['data']){
        if(data['data'].includes('ERREUR')){
          commit('errorResponse');
          if(!data['data'][0].includes('quantité') && !data['data'][1]){
            alert(data['data']);
          }
          else if(data['data'][1] && !data['data'][1].includes('quantité')){
            if(data['data'][2]){
              commit('errorRef', data['data'][2]);
            }
            alert(data['data']);
          }
          //location.reload();
          return
        };
        commit("succesResponse",'Reservation');
      }
        else{
          commit("errorResponse");
          location.reload()
        }
      //commit("itemInBasket", data["data"]);
    })
    .catch((e) => console.log(e));
        break;
    case 'ConfirmBooking':
          //console.log('retirer reservation');
          dispatch("fetchConfirmBooking", basketData)
       .then((data) => {
         if(data['data']){
          //console.log(data['data']);
          if(data['data'].includes('ERREUR')){
            alert(data['data']);
            location.reload();
            return
          }
          else if(data['data'].includes('Error')){
            commit('errorResponse');
            location.reload();
            return
          };
           //location.reload()
           commit("succesResponse",'Confirmation');
        }
         else{
           alert(`Une erreur est survenue, 
           impossible de retirer la quantité demandée`);
           commit("requestResponse");
         }
         //commit("itemInBasket", data["data"]);
       })
       .catch((e) => console.log(e));
           break;
    case 'CancelBooking':
            //console.log('annuler reservation');
            dispatch("fetchCancelBooking", basketData)
         .then((data) => {
           if(data['data']){
             //location.reload()
             commit("succesResponse",'Annulation');
         }
           else{
             alert('L\'annulation a échouée ');
             commit("requestResponse");
           }
         })
         .catch((e) => console.log(e));
             break;
    case 'Return':
       //console.log('rendre');
       dispatch("fetchReturnOrder", basketData)
    .then((data) => {
      if(data['data']){
        if(data['data'].includes('ERREUR')){
          commit('errorResponse');
          if(!data['data'][0].includes('quantité') && !data['data'][1]){
            alert(data['data']);
          }
          else if(data['data'][1] && !data['data'][1].includes('quantité')){
            if(data['data'][2]){
              commit('errorRef', data['data'][2]);
            }
            alert(data['data']);
          }
          //location.reload();
          return
        };
        commit("succesResponse",'Retour');
        if(data['data'].includes('CREATE')){
          var infoMessage = '';
          for( let i in data['data']){
            if (data["data"][i].includes('La ref a été créée')){
              infoMessage =data["data"][i];
              //console.log(infoMessage );
            }}
        }
     }
        else{
          commit("errorResponse");
          location.reload()
        }
      //commit("itemInBasket", data["data"]);
    })
    .catch((e) => console.log(e));
        break;
    default:
        break;
};
},
async fetchWithdrawOrder({ commit, dispatch }, basketData) {
  //console.log(basketData);
 
  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            order: basketData.orderBasket,
            username : basketData.username,
            user_id: basketData.user_id,
          }),
        };

  let response = await fetch(
    appPath+"/items/basket_withdraw",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchBookOrder({ commit, dispatch }, basketData) {
  //console.log(basketData);
 
  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            order: basketData.orderBasket,
            username : basketData.username,
            user_id: basketData.user_id,
          }),
        };

  let response = await fetch(
    appPath+"/items/basket_booking",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchConfirmBooking({ commit, dispatch }, basketData) {
  //console.log(basketData);
 
  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            order: basketData.orderBasket,
            username : basketData.username,
            user_id: basketData.user_id,
          }),
        };

  let response = await fetch(
    appPath+"/items/confirm_booking",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchCancelBooking({ commit, dispatch }, basketData) {
 
  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            order: basketData.orderBasket,
            username : basketData.username,
            user_id: basketData.user_id,
          }),
        };

  let response = await fetch(
    appPath+"/items/cancel_booking",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchReturnOrder({ commit, dispatch }, basketData) {
  //console.log(basketData);
 
  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            order: basketData.orderBasket,
            username : basketData.username,
            user_id: basketData.user_id,
          }),
        };

  let response = await fetch(
    appPath+"/items/basket_return",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
//history API (not items)
//CREATE REQUEST
async fetchLimitTool({ commit, dispatch }, basketData) {

    const requestOptions = {
      method: "POST",
      headers: {
         Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json" },
      body: JSON.stringify({
        limitTool: basketData.limitTool,
        username : basketData.username,
        user_id: basketData.user_id,
      }),
    };

    let response = await fetch(
      appPath+"/history/limit_tool",
      requestOptions
    );
    if (!response.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || response.status;
      //console.log('not response ok, error : ' + error);
      alert("une erreur innattendue s'est produite");
      return Promise.reject(error);
    }
    return await response.json();
  },

///GET ORDERS
getAllMyOrders: ({ commit, dispatch }, usernameAndId) => {
  if(usernameAndId.includes('+')){
    dispatch("fetchMyPastOrdersExtended", usernameAndId)
    .then((data) => {
      if(data['data']){
        //console.log('PAST ORDERS EXTENDED');
        //console.log(data['data'])
      }
      else{
        alert('requete commandes impossible');
      }
      commit("myOrdersExtended", data["data"]);
    })
    .catch((e) => console.log(e));
  }
  else if(usernameAndId.includes('pastsBookings')){
    const infos = usernameAndId.split("Bookings")[1];
    dispatch("fetchMyPastsBookings", infos)
    .then((data) => {
      if(data['data']){
        //console.log('PAST BOOKINGS');
        //console.log(data['data'])
      }
      else{
        alert('requete commandes impossible');
      }
      commit("myBookings", data["data"]);
    })
    .catch((e) => console.log(e));
  }
  else if (usernameAndId.includes('nowBookings')){
    const infos = usernameAndId.split("Bookings")[1];
    dispatch("fetchMyBookings", infos)
    .then((data) => {
      if(data['data']){
        //console.log('NOW BOOKINGS');
        //console.log(data['data'])
      }
      else{
        alert('requete commandes impossible');
      }
      commit("myBookings", data["data"]);
    })
    .catch((e) => console.log(e));
  }
  else {
    if(!usernameAndId.includes('GetOnlyCables')){
       dispatch("fetchMyPastOrders", usernameAndId)
    .then((data) => {
      if(data['data']){
        //console.log('PAST ORDERS');
        //console.log(data['data'])
      }
      else{
        alert('requete commandes impossible');
      }
      commit("myOrders", data["data"]);
    })
    .catch((e) => console.log(e));

       dispatch("fetchMyBookings", usernameAndId)
    .then((data) => {
      if(data['data']){
        //console.log('PAST BOOKINGS');
        //console.log(data['data'])
      }
      else{
        alert('requete reservations impossible');
      }
      commit("myBookings", data["data"]);
    })
    .catch((e) => console.log(e));
  }
    dispatch("fetchMyBorrowReels", usernameAndId)
    .then((data) => {
      if(data['data']){
        //console.log('MY REELS');
        //console.log(data['data'])
      }
      else{
        alert('requete tourets impossible');
      }
      commit("myReels", data["data"]);
    })
    .catch((e) => console.log(e));
}},

async fetchMyPastOrders({ commit, dispatch }, usernameAndId) {
  let response = await fetch(appPath+"/history/my_orders/" + usernameAndId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchMyBookings({ commit, dispatch }, usernameAndId) {
  let response = await fetch(appPath+"/history/my_bookings/" + usernameAndId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchMyPastsBookings({ commit, dispatch }, usernameAndId) {
  let response = await fetch(appPath+"/history/my_pasts_bookings/" + usernameAndId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchMyBorrowReels({ commit, dispatch }, usernameAndId) {
  let response = await fetch(appPath+"/history/my_reels/" + usernameAndId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchMyPastOrdersExtended({ commit, dispatch }, usernameAndId) {
  let response = await fetch(appPath+"/history/my_orders_extended/" + usernameAndId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
// PUT CABLES BORROW
borrowCables: ({ commit, dispatch }, borrow) => {
  if(borrow.action === 'borrow'){
  dispatch("fetchCablesBorrow", borrow)
.then((data) => {
 if(data['data']){
  if(data['data'].includes("ERREUR")){
    alert(data['data']);
  }
   //alert('Emprunt effectué')
   //console.log(data['data'])
  commit("succesResponse", 'Confirmation');
 }
 else{
   alert('requete impossible');
 }
})
.catch((e) => console.log(e));
}
else if (borrow.action === 'return'){

  dispatch("fetchReturnBorrow", borrow)
.then((data) => {
 if(data['data']){
   //alert('Emprunt retourné')
   //console.log(data['data'])
   commit("succesResponse", "ReturnBorrow");
 }
 else{
   alert('requete impossible');
 }
 
})
.catch((e) => console.log(e));
}
else if (borrow.action === 'booking'){

  dispatch("fetchCablesBooking", borrow)
.then((data) => {
 if(data['data']){
   if(data['data'].includes("ERREUR")){
     alert(data['data']);
   }
   //alert('touret reservé')
   //console.log(data['data'])
  commit("succesResponse", 'Reservation');
 }
 else{
   alert('requete impossible');
 }

})
.catch((e) => console.log(e));
}
else if (borrow.action === 'unbooking'){

  dispatch("fetchCablesUnbooking", borrow)
.then((data) => {
 if(data['data']){
   //alert('Reservation annulée')
   //console.log(data['data'])
    commit("succesResponse", "Annulation");
 }
 else{
   alert('requete impossible');
 }

})
.catch((e) => console.log(e));
}
},

async fetchCablesBorrow({ commit, dispatch }, borrow) {
  //console.log(borrow);
 
  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            item_id: borrow.item_id,
            username : borrow.username,
            user_id: borrow.user_id,
            item : borrow.item,
            location : borrow.location,
          }),
        };

  let response = await fetch(
    appPath+"/items/cables_borrow",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchReturnBorrow({ commit, dispatch }, borrow) {
  //console.log(borrow);

  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            item_id: borrow.item_id,
            username : borrow.username,
            location : borrow.location,
            ref : borrow.ref,
            meter : borrow.meter,
            qtty_in : borrow.qtty_in,
            qtty_max : borrow.qtty_max,
            user_id: borrow.user_id,
            item : borrow.item
          }),
        };

  let response = await fetch(
    appPath+"/items/return_borrow",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchCablesBooking({ commit, dispatch }, borrow) {
  //console.log(borrow);
 
  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            item_id: borrow.item_id,
            username : borrow.username,
            user_id: borrow.user_id,
            item : borrow.item,
            location : borrow.location
          }),
        };

  let response = await fetch(
    appPath+"/items/cables_booking",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchCablesUnbooking({ commit, dispatch }, borrow) { 
  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            item_id: borrow.item_id,
            username : borrow.username,
            user_id: borrow.user_id,
            item : borrow.item,
            ref : borrow.ref,
            location : borrow.location
          }),
        };

  let response = await fetch(
    appPath+"/items/cables_unbooking",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},

// SEARCH BOROW
searchBorrow: ({ commit, dispatch }, ids) => {
  dispatch("fetchSearchBorrow", ids)
.then((data) => {
 if(data['data']){
   //alert('recherche effectuée')
   //console.log('Succes search');
   //console.log(data['data'])
 }
 else{
   alert('requete impossible');
 }
 commit("showBorrow", {
   username : data['data'][0].username,
   item_id : ids.split("_")[0]
 });
})
.catch((e) => console.log(e));
},

// FETCH SEARCH USER BORROW ----------------------------------------------
async fetchSearchBorrow({ commit, dispatch }, ids) {
          //console.log(id);
          let response = await fetch(appPath+"/items/search_borrow/" + ids, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          });
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            //console.log('not response ok, error : ' + error);
            alert("une erreur innattendue s'est produite");
            return Promise.reject(error);
          }
          return await response.json();
        },

getToolsLimitUser: ({ commit, dispatch }, usernameAndId) => {
      dispatch("fetchToolsLimitUser", usernameAndId)
      .then((data) => {
        if(data['data']){
          //console.log('Tools Limits DATA');
          //console.log(data['data'])
        }
        else{
          alert('requete limitation outils impossible');
        }
      commit("toolsLimit", data["data"]);
      })
      .catch((e) => console.log(e));
    },
  
  async fetchToolsLimitUser({ commit, dispatch }, usernameAndId) {
    let response = await fetch(appPath+"/history/tools_for_user/" + usernameAndId, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      },
    });
    if (!response.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || response.status;
      //console.log('not response ok, error : ' + error);
      alert("une erreur innattendue s'est produite");
      return Promise.reject(error);
    }
    return await response.json();
  },
  getMyAlerts: ({ commit, dispatch }, userId) => {
    dispatch("fetchGetMyAlerts", userId)
    .then((data) => {
      if(data['data']){
       // console.log('myAlerts DATA');
        //console.log(data['data'])
      }
      else{
        alert('requete myAlerts impossible');
      }
    commit("myAlerts", data["data"]);
    })
    .catch((e) => console.log(e));
    dispatch("fetchGetMyCablesAlerts", userId)
    .then((data) => {
      if(data['data']){
        //console.log('myCablesAlerts DATA');
        //console.log(data['data'])
      }
      else{
        alert('requete myCablesAlerts impossible');
      }
    commit("myCableAlerts", data["data"]);
    })
    .catch((e) => console.log(e));
    dispatch("fetchGetMyClientsAlerts", userId)
    .then((data) => {
      if(data['data']){
        //console.log('myClientsAlerts DATA');
        //console.log(data['data'])
      }
      else{
        alert('requete myClientsAlerts impossible');
      }
    commit("myClientsAlerts", data["data"]);
    })
    .catch((e) => console.log(e));
    /*dispatch("fetchGetMyEmptyCables", userId)
    .then((data) => {
      if(data['data']){
        console.log('myEmptyCables DATA');
        console.log(data['data'])
      }
      else{
        alert('requete myEmptyCables impossible');
      }
    commit("myEmptyCables", data["data"]);
    })
    .catch((e) => console.log(e));*/
  },
  getPricesAndSuppliers: ({ commit, dispatch }, Obj) => {
    dispatch("fetchPricesAndSuppliers", Obj)
    .then((data) => {
      if(data['data']){
        //console.log('Prices And Suppliers');
        //console.log(data['data'])
      
      commit("adminBasketInfos", data["data"]);}else{
        alert(`une erreur s'est produite avec un produit du panier, 
        une référence de votre panier n'est pas reconnue, elle a du être modifiée après ajout`)
      }
    })
    .catch((e) => console.log(e));
  },

  async fetchGetMyAlerts({ commit, dispatch }, userId) {
    let response = await fetch(appPath+"/admin_order/my_alerts/" + userId, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      },
    });
    if (!response.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || response.status;
      //console.log('not response ok, error : ' + error);
      alert("une erreur innattendue s'est produite");
      return Promise.reject(error);
    }
    return await response.json();
  },
  async fetchPricesAndSuppliers({ commit, dispatch }, Obj) {
    //console.log(Obj[0]);
   
    const requestOptions = {
            method: "POST",
            headers: {  Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
              "Content-Type": "application/json" },
            body: JSON.stringify({
              refs: Obj[0],
              user_id: Obj[1],
            }),
          };
  
    let response = await fetch(
      appPath+"/admin_order/prices_and_suppliers",
      requestOptions
    );
    if (!response.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || response.status;
      //console.log('not response ok, error : ' + error);
      alert("une erreur innattendue s'est produite");
      return Promise.reject(error);
    }
    return await response.json();
  },
  async fetchGetMyCablesAlerts({ commit, dispatch }, userId) {
    let response = await fetch(appPath+"/admin_order/my_cables_alerts/" + userId, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      },
    });
    if (!response.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || response.status;
      //console.log('not response ok, error : ' + error);
      alert("une erreur innattendue s'est produite");
      return Promise.reject(error);
    }
    return await response.json();
  },
  async fetchGetMyEmptyCables({ commit, dispatch }, userId) {
    let response = await fetch(appPath+"/admin_order/my_empty_cables/" + userId, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      },
    });
    if (!response.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || response.status;
      //console.log('not response ok, error : ' + error);
      alert("une erreur innattendue s'est produite");
      return Promise.reject(error);
    }
    return await response.json();
  },
  async fetchGetMyClientsAlerts({ commit, dispatch }, userId) {
    let response = await fetch(appPath+"/admin_order/my_clients_alerts/" + userId, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      },
    });
    if (!response.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || response.status;
      //console.log('not response ok, error : ' + error);
      alert("une erreur innattendue s'est produite");
      return Promise.reject(error);
    }
    return await response.json();
  },

  // PUT/POST ADMIN ORDER
saveAdminOrder: ({ commit, dispatch }, basketData) => {
          dispatch("fetchSaveAdminOrder", basketData)
       .then((data) => {
         if(data['data']){
           if(data['data'].includes("ERREUR")){
             alert(data['data']);
             commit("requestResponse");
             return
           }
           //location.reload()
           //console.log('Admin order data');
           commit("succesResponse",'Confirmation');
        }
         else{
          alert('La commande a échouée');
           commit("requestResponse");
         }
         //commit("itemInBasket", data["data"]);
       })
       .catch((e) => console.log(e));
},
async fetchSaveAdminOrder({ commit, dispatch }, basketData) {
  //console.log(basketData[0]);
  const connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
  let key;
      //key for only web app native request (!postman)
      if (
        (connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
 
  const requestOptions = {
          method: "POST",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            order: basketData,
            username : basketData.username,
            user_id: basketData.id,
            key :key,
            bc_number : basketData.bc_number
          }),
        };

  let response = await fetch(
    appPath+"/admin_order/save_admin_order",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},

///GET Receptions
getAdminReceptions: ({ commit, dispatch }, userId) => {
       dispatch("fetchAdminReceptions", userId)
    .then((data) => {
      if(data['data']){
        //console.log('Receptions Data');
        //console.log(data['data'])
      }
      else{
        alert('requete impossible');
      }
      commit("myReceptions", data["data"]);
    })
    .catch((e) => console.log(e));

       dispatch("fetchAdminRemainders",userId)
    .then((data) => {
      if(data['data']){
        //console.log('Remainders data');
        //console.log(data['data'])
      }
      else{
        alert('requete impossible');
      }
      commit("myRemainders", data["data"]);
      commit("succesResponse",'Retour');
    })
    .catch((e) => console.log(e));

    
},

async fetchAdminReceptions({ commit, dispatch }, userId) {
  let response = await fetch(appPath+"/admin_order/my_receptions/" + userId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchAdminRemainders({ commit, dispatch }, userId) {
  let response = await fetch(appPath+"/admin_order/my_remainders/" + userId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},

//UPDATE Receptions
updateReception: ({ commit, dispatch }, recepData) => {
       dispatch("fetchPutReception", recepData)
    .then((data) => {
      if(data['data']){
        //console.log(data['data']);
        const obj = data['data'];
        //Gestion d'erreur
        if (obj === 'la ref n\'existe nulle part, ERREUR'){
          alert('la reception contient un objet qui a été supprimé, ref introuvable, ERREUR');
          location.reload();
          return
        }
        // remainder-only = no suppressing data on order in progress
        if(Object.values(obj).indexOf('remainder-only') > -1){
          commit("succesResponse",'Confirmation');
          return;}
          //mixed = reception + remainder, suppress only completed
        else if(Object.values(obj).indexOf('mixed') > -1){
            recepData.status = 'mixed'};

      dispatch("fetchDeleteReception", recepData)
        .then((data) => {
      if(data['data']){
      //console.log(data['data'])
      if(recepData.status === 'completed' || recepData.status === 'mixed'){
        commit("succesResponse",'Confirmation');
      }
      else if(recepData.status === 'canceled'){
        commit("succesResponse",'Annulation');
      }
      
  }
  else{
    alert('suppression impossible');
      }
      })
  .catch((e) => console.log(e));
      }
        else{
          alert('une erreur s\'est produite');
          location.reload();
        }
      //commit("itemInBasket", data["data"]);
    })
    .catch((e) => console.log(e));
},
async fetchPutReception({ commit, dispatch }, recepData) {
  //console.log(recepData);
  let saveQttyReport;
  if(recepData.saveQttyReport){
    saveQttyReport = recepData.saveQttyReport;
  }
  else{
    saveQttyReport = 0;
  }
  let key;
      //key for only web app native request (!postman)
      const connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
      if (
        (connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
  
  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            order_number: recepData.order_number,
            status : recepData.status,
            saveQttyReport : saveQttyReport,
            username : recepData.username,
            user_id: recepData.user_id,
            key :key
          }),
        };

  let response = await fetch(
    appPath+"/admin_order/update_reception",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchDeleteReception({ commit, dispatch }, recepData){
  let key;
      //key for only web app native request (!postman)
      const connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
      if (
        (connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
const requestOptions = {
  method: "DELETE",
  headers: {
  "Content-Type": "application/json",
  Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
},
  body: JSON.stringify({
    number : recepData.order_number,
    status : recepData.status,
    username : recepData.username,
    user_id: recepData.user_id,
    key:key
  }),
};

let response = await fetch(
  appPath+"/admin_order/delete_reception",
  requestOptions
);
if (!response.ok) {
  alert("Une erreur empêche la requête");
  //stop loader
 this.putLoader = false;
  // get error message from body or default to response status
  const error = (data && data.message) || response.status;
  //console.log('not response ok, error : ' + error);
  //console.log(error);
  return Promise.reject(error);
}
return await response.json();
},
//GET ADDRESSES
getAddresses: ({ commit, dispatch }, userId) => {
      dispatch("fetchAddresses", userId)
      .then((data) => {
        if(data['data']){
          //console.log('fetchAddresses');
          //console.log(data['data'])
        }
        else{
          alert('requete impossible');
        }
      commit("allAddresses", data["data"]);
      })
      .catch((e) => console.log(e));
    },
async fetchAddresses({ commit, dispatch }, userId) {
      let response = await fetch(appPath+"/admin_order/get_addresses/" + userId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },

    // ----------------- HISTORY PAGE-----------------------------------
    getGlobalHistory: ({ commit, dispatch }, userId) => {
      dispatch("fetchGlobalHistory", userId)
      .then((data) => {
        if(data['data']){
          //console.log('fetchGlobalHistory');
          //console.log(data['data'])
        }
        else{
          alert('requete impossible');
        }
      commit("globalHistory", data["data"]);
      })
      .catch((e) => console.log(e));
    },
    async fetchGlobalHistory({ commit, dispatch }, userId) {
      let response = await fetch(appPath+"/history/global_history/" + userId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },

    getReceptionHistory: ({ commit, dispatch }, userId) => {
      dispatch("fetchReceptionHistory", userId)
      .then((data) => {
        if(data['data']){
          //console.log('fetchReceptionHistory');
          //console.log(data['data'])
        }
        else{
          alert('requete impossible');
        }
      commit("receptionHistory", data["data"]);
      })
      .catch((e) => console.log(e));
    },
    async fetchReceptionHistory({ commit, dispatch }, userId) {
      let response = await fetch(appPath+"/history/reception_history/" + userId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },

    getUserHistory: ({ commit, dispatch }, userId) => {
      dispatch("fetchUserHistory", userId)
      .then((data) => {
        if(data['data']){
          //console.log('fetchUserHistory');
          //console.log(data['data'])
        }
        else{
          alert('requete impossible');
        }
      commit("userHistory", data["data"]);
      })
      .catch((e) => console.log(e));
    },
    async fetchUserHistory({ commit, dispatch }, userId) {
      let response = await fetch(appPath+"/history/user_history/" + userId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },

    getAdminChangesHistory: ({ commit, dispatch }, userId) => {
      dispatch("fetchAdminChangesHistory", userId)
      .then((data) => {
        if(data['data']){
          //console.log('fetchAdminChangesHistory');
          //console.log(data['data'])
        }
        else{
          alert('requete impossible');
        }
      commit("adminChangesHistory", data["data"]);
      })
      .catch((e) => console.log(e));
    },
    async fetchAdminChangesHistory({ commit, dispatch }, userId) {
      let response = await fetch(appPath+"/history/admin_changes_history/" + userId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },

    getBookingHistory: ({ commit, dispatch }, userId) => {
      const past = 'history_'+userId;
      const now = 'inProgress_'+userId;
      dispatch("fetchBookingHistory", past)
      .then((data) => {
        if(data['data']){
          //console.log('fetchBookingHistory');
          //console.log(data['data'])
        }
        else{
          alert('requete impossible');
        }
      commit("bookingHistory", data["data"]);
      })
      .catch((e) => console.log(e));
      dispatch("fetchBookingHistory", now)
      .then((data) => {
        if(data['data']){
          //console.log('fetchBookingHistoryNow');
          //console.log(data['data'])
        }
        else{
          alert('requete impossible');
        }
      commit("bookingInProgress", data["data"]);
      })
      .catch((e) => console.log(e));
    },
    async fetchBookingHistory({ commit, dispatch }, userId) {
      let response = await fetch(appPath+"/history/booking_history/" + userId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },

    getReelsHistory: ({ commit, dispatch }, userId) => {
      const past = 'history_'+userId;
      const now = 'movement_'+userId;
      dispatch("fetchReelsHistory", past)
      .then((data) => {
        if(data['data']){
          //console.log('fetchReelsHistory PAST');
          //console.log(data['data'])
        }
        else{
          alert('requete impossible');
        }
      commit("reelsHistory", data["data"]);
      })
      .catch((e) => console.log(e));

      dispatch("fetchReelsHistory", now)
      .then((data) => {
        if(data['data']){
          //console.log('fetchReelsHistory NOW');
          //console.log(data['data'])
        }
        else{
          alert('requete impossible');
        }
      commit("reelsMovement", data["data"]);
      })
      .catch((e) => console.log(e));
    },
    async fetchReelsHistory({ commit, dispatch }, userId) {
      let response = await fetch(appPath+"/history/reels_history/" + userId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },

    getToolsHistory: ({ commit, dispatch }, userId) => {
      dispatch("fetchToolsHistory", userId)
      .then((data) => {
        if(data['data']){
          //console.log('fetchToolsHistory');
          //console.log(data['data'])
        }
        else{
          alert('requete impossible');
        }
      commit("toolsHistory", data["data"]);
      })
      .catch((e) => console.log(e));
    },
    async fetchToolsHistory({ commit, dispatch }, userId) {
      let response = await fetch(appPath+"/history/tools_history/" + userId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },

    // PUT INTERVAL DATE
putIntervalDate: ({ commit, dispatch }, historyData) => {
       dispatch("fetchIntervalDate", historyData)
    .then((data) => {
      if(data['data']){
        if(data['data'].includes('ERREUR')){
          alert(data['data']);
          return
        };
        commit("intervalDate", data["data"]);    
     }
     else{
        alert('La requête d\'intervalle de date a échoué');
      }
    })
    .catch((e) => console.log(e));
},
async fetchIntervalDate({ commit, dispatch }, historyData) {
  //console.log(historyData);
 
  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            date: historyData.date,
            compOrigin : historyData.compOrigin,
            username : historyData.username,
            user_id: historyData.user_id,
          }),
        };

  let response = await fetch(
    appPath+"/history/interval_date",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},

// refresh expired booking_in_progress
refreshBookingInProgress: ({ commit, dispatch }, userId) => {
  dispatch("fetchDeleteExpired", userId)
  .then((data) => {
    if(data['data']){
      //console.log('fetchDeleteExpired');
      //console.log(data['data'])
    }
    else{
      alert('requete impossible');
    }
  })
  .catch((e) => console.log(e));
},
async fetchDeleteExpired({ commit, dispatch }, userId){
  let key;
      //key for only web app native request (!postman)
      const connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
      if (
        (connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
const requestOptions = {
  method: "DELETE",
  headers: {
  "Content-Type": "application/json",
  Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
},
  body: JSON.stringify({
    user_id: userId,
    key:key
  }),
};

let response = await fetch(
  appPath+"/history/delete_expired",
  requestOptions
);
if (!response.ok) {
  alert("Une erreur empêche la requête");
  //stop loader
 this.putLoader = false;
  // get error message from body or default to response status
  const error = (data && data.message) || response.status;
  //console.log('not response ok, error : ' + error);
  //console.log(error);
  return Promise.reject(error);
}
return await response.json();
},
getAllUsersIds: ({ commit, dispatch }, id) => {
  dispatch("fetchAllUsersIds", id)
    .then((data) => {
      //console.log(data["data"]);
      commit("usersIds", data["data"]);
    })
    .catch((e) => console.log(e));
},
// FETCH ONE USER ----------------------------------------------
async fetchAllUsersIds({ commit, dispatch }, id) {
          //console.log(id);
          let response = await fetch(appPath+"/auth/allUsersIds/" + id, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          });
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            //console.log('not response ok, error : ' + error);
            alert("une erreur innattendue s'est produite");
            return Promise.reject(error);
          }
          return await response.json();
        },
    
//ACTIVITY REQUEST -------------------------------------------------
newActivity: ({ commit, dispatch }, obj) => {
  //console.log(obj);
  dispatch("fetchNewActivity", obj)
    .then((data) => {
      //console.log(data["data"]);
      if(data["data"]){
      commit("activityResponse", true);
    dispatch('getAllActivities',obj.user_id)}
    })
    .catch((e) => console.log(e));
},

async fetchNewActivity({ commit, dispatch }, obj) {
  //console.log(newActivity);
 
  const requestOptions = {
          method: "POST",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            newActivity: obj.newActivity,
            user_id: obj.user_id,
          }),
        };

  let response = await fetch(
    appPath+"/activities/new_activity",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
getAllActivities: ({ commit, dispatch }, userId) => {
  dispatch("fetchGetAllActivities", userId)
  .then((data) => {
    if(data['data']){
      //console.log('fetchGetAllActivities');
      //console.log(data['data'])
      commit("allActivites", data["data"]);
    }
    else{
      alert('requete impossible');
    }
  
  })
  .catch((e) => console.log(e));
},
async fetchGetAllActivities({ commit, dispatch }, userId) {
  let response = await fetch(appPath+"/activities/allActivities/" + userId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},

// GET ALL ITEMS ------------------
getOnlyClientsItems: ({ commit, dispatch }, id) => {
  dispatch("fetchGetClientsAndDetails", id)
    .then((data) => {
      commit("itemClientsAndDetails", data["data"]);
    })
    .catch((e) => console.log(e));
},

createConstruction: ({ commit, dispatch }, obj) => {
  //console.log(obj);
  dispatch("fetchCreateConstruction", obj)
    .then((data) => {
      //console.log(data["data"]);
      if(data["data"]){
      commit("constructionResponse", true);
    dispatch('getAllConstructions','1+2+3+4+5+m1'+'_'+obj.user_id);
  }
    })
    .catch((e) => console.log(e));
},

async fetchCreateConstruction({ commit, dispatch }, obj) {
  //console.log(newActivity);
 
  const requestOptions = {
          method: "POST",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            client : obj.client,
            town : obj.town,
            activity : obj.activity,
            date : obj.date,
            coordinator : obj.coordinator,
            various : obj.various,
            user_id: obj.user_id,
          }),
        };

  let response = await fetch(
    appPath+"/activities/new_construction",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},

getAllConstructions: ({ commit, dispatch }, userId) => {
  dispatch("fetchGetAllConstructions", userId)
  .then((data) => {
    if(data['data']){
      //console.log('fetchGetAllConstructions');
      //console.log(data['data'])
      commit("allConstructions", data["data"]);
    }
    else{
      alert('requete impossible');
    }
  
  })
  .catch((e) => console.log(e));
},
async fetchGetAllConstructions({ commit, dispatch }, userId) {
  let response = await fetch(appPath+"/activities/allConstructions/" + userId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
getInProgressConstructions: ({ commit, dispatch }, userId) => {
  dispatch("fetchGetInProgressConstructions", userId)
  .then((data) => {
    if(data['data']){
      //console.log('fetchGetInProgressConstructions');
      //console.log(data['data'])
      commit("inProgressConstructions", data["data"]);
    }
    else{
      alert('requete impossible');
    }
  
  })
  .catch((e) => console.log(e));
},
async fetchGetInProgressConstructions({ commit, dispatch }, userId) {
  let response = await fetch(appPath+"/activities/in_progress_constructions/" + userId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
modifyConstructionStatus: ({ commit, dispatch }, obj) => {
  //console.log(obj);
  dispatch("fetchModifyConstructionStatus", obj)
    .then((data) => {
      //console.log(data);
      //console.log(data["data"]);
      if(data["data"]){
      commit("constructionStatusResponse", true);
    dispatch('getAllConstructions','1+2+3+4+5+m1'+'_'+obj.user_id);
  }
    })
    .catch((e) => console.log(e));
},
async fetchModifyConstructionStatus({ commit, dispatch }, obj) {
  //console.log(newActivity);
 
  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            construction : obj.construction,
            status_ : obj.status_,
            username : obj.username,
            user_id: obj.user_id,
          }),
        };

  let response = await fetch(
    appPath+"/activities/modify_construction_status",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
//getAllItemsInBasket
receptionClient: ({ commit, dispatch }, receptionData) => {
  const noInjection = receptionData['noInjection'];
  delete receptionData['noInjection'];
  dispatch("fetchReceptionClient", receptionData)
    .then((data) => {
    //console.log(data["data"]);
    if(data['data'] && data['data'].length != 0){
     commit("receptionClient", data["data"]);}
     else{
      commit("receptionClientERROR", true);
     };
     commit("requestEnd", true);
    })
    .catch((e) => console.log(e));
    if(noInjection){
      commit("receptionClientStockERROR", true);
      return
    }
    dispatch("fetchReceptionClientStock", receptionData)
    .then((data) => {
      //console.log(data["data"]);
      //commit("itemInBasket", data["data"]);
      if(data['data'] && data['data'].length != 0){
     commit("receptionClientStock", data["data"]);}
     else{
      commit("receptionClientStockERROR", true);
     }
     commit("requestEnd", true);
    })
    .catch((e) => console.log(e));
},

async fetchReceptionClient({ commit, dispatch },receptionData) {
  //console.log(orderBasket);
 
  const requestOptions = {
          method: "POST",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            obj: receptionData,
            user_id: receptionData["id"],
          }),
        };

  let response = await fetch(
    appPath+"/activities/reception_client",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
async fetchReceptionClientStock({ commit, dispatch },receptionData) {
  //console.log(orderBasket);
 
  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            obj: receptionData,
            user_id: receptionData["id"],
          }),
        };

  let response = await fetch(
    appPath+"/items/reception_client_stock",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
getAssignementReport: ({ commit, dispatch }, userId) => {
  dispatch("fetchGetAssignementReport", userId)
  .then((data) => {
    if(data['data']){
      //console.log('fetchGetAssignementReport');
      //console.log(data['data'])
      commit("allAssignements", data["data"]);
    }
    else{
      alert('requete impossible');
    }
  
  })
  .catch((e) => console.log(e));
},
async fetchGetAssignementReport({ commit, dispatch }, userId) {
  let response = await fetch(appPath+"/activities/assignement_report/" + userId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},

sendConstructionResume: ({ commit, dispatch }, constructionObj) => {
  //console.log(constructionObj); 
  dispatch("fetchSendCableMeters", constructionObj)
    .then((data) => {
      if(data['data']){
        //mémoriser le dernier élément de la liste
        const allCablesUsed = data['data'][(data['data'].length) - 1] ;
        if(data['data'].includes('ERREUR')){
          alert('Une erreur inattendue empêche la validation des données (metrés cables)');
          commit("constructionResumeResponse", true);
          return
        }
        //console.log(data);
        dispatch("fetchSendConstructionResume", constructionObj)
        .then((data) => { 
          if(data['data']){
            //console.log('is any data ?');
          //console.log(data);
          if(data['data'].includes('ERREUR')){
       //*------------------------------------------------------------*     
      //----* SI ERREUR ON DOIT ANNULER LA SORTIE PRECEDENTE ET FAIRE UN REVERSE 
            //(contrainte obligatoire du au 2 DB différentes...)
            
            if(allCablesUsed){
              constructionObj['error'] = allCablesUsed;
            dispatch("fetchSendCableMeters", constructionObj)
    .then((data) => {
      if(data['data']){
        // normalement il y tj une réponse, 
        //donc pas besoin de prévoir un rollback si no data, slmnt si erreur
        if(data['data'].includes('ERREUR')){
          alert('Une erreur inattendue empêche la validation des données (metrés cables)');
          commit("constructionResumeResponse", true);
          return
        }
        
      }
      
    })
    .catch((e) => console.log(e));
  };
    //----FIN DU REVERSE EN CAS ERREUR *
    //*------------------------------------------------------------* 
    alert(`Une erreur inattendue empêche la validation des données (quantités materiels)`);
            commit("constructionResumeResponse", true);
            return
          }
          commit("constructionResumeResponse", data["data"]);
          
        }
        })
        .catch((e) => console.log(e));
      }
      //console.log(data["data"].location);
      //console.log(data["data"].location);
      //commit("itemInBasket", data["data"]);
    })
    .catch((e) => console.log(e));
},
// FETCH POST CONSTRUCTION RESUME----------------------------------------------
async fetchSendConstructionResume({ commit, dispatch },constructionObj) {
  //console.log(orderBasket);
 
  const requestOptions = {
          method: "POST",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            obj: constructionObj,
            user_id: constructionObj["id"],
          }),
        };

  let response = await fetch(
    appPath+"/activities/construction_resume",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
// FETCH POST CABLE METERS--------------------------------------------
async fetchSendCableMeters({ commit, dispatch },constructionObj) {
  //console.log(orderBasket);
  var rollBack = false;
 if(constructionObj['error']){
  rollBack = constructionObj['error'];
 }
  const requestOptions = {
          method: "PUT",
          headers: {  Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            obj: constructionObj,
            user_id: constructionObj["id"],
            rollBack : rollBack
          }),
        };

  let response = await fetch(
    appPath+"/items/update_cable_meters",
    requestOptions
  );
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},// FETCH POST CONSTRUCTION HISTORY----------------------------------------------

//--------------ACTIVITY HISTORY -------------------
getConstructionHistory: ({ commit, dispatch }, userId) => {
  dispatch("fetchConstructionHistory", userId)
  .then((data) => {
    if(data['data']){
      //console.log('fetchConstructionHistory');
      //console.log(data['data'])
    }
    else{
      alert('requete impossible');
    }
  commit("constructionsHistory", data["data"]);
  })
  .catch((e) => console.log(e));
},
async fetchConstructionHistory({ commit, dispatch }, userId) {
  let response = await fetch(appPath+"/activities/constructions_history/" + userId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
getConstructionStatusHistory: ({ commit, dispatch }, userId) => {
  dispatch("fetchConstructionStatusHistory", userId)
  .then((data) => {
    if(data['data']){
      //console.log('fetchConstructionStatusHistory');
      //console.log(data['data'])
    }
    else{
      alert('requete impossible');
    }
  commit("constructionsStatusHistory", data["data"]);
  })
  .catch((e) => console.log(e));
},
async fetchConstructionStatusHistory({ commit, dispatch }, userId) {
  let response = await fetch(appPath+"/activities/constructions_status_history/" + userId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
getAssignementHistory: ({ commit, dispatch }, userId) => {
  dispatch("fetchAssignementHistory", userId)
  .then((data) => {
    if(data['data']){
      //console.log('fetchAssignementHistory');
      //console.log(data['data'])
    }
    else{
      alert('requete impossible');
    }
  commit("assignementHistory", data["data"]);
  })
  .catch((e) => console.log(e));
},
async fetchAssignementHistory({ commit, dispatch }, userId) {
  let response = await fetch(appPath+"/activities/assignement_history/" + userId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
    // PUT INTERVAL DATE
    putIntervalActivities: ({ commit, dispatch }, historyData) => {
      dispatch("fetchIntervalActivities", historyData)
   .then((data) => {
     if(data['data']){
       if(data['data'].includes('ERREUR')){
         alert(data['data']);
         return
       };
       //console.log(data["data"]);
       commit("intervalDateActivities", data["data"]);    
    }
    else{
       alert('La requête d\'intervalle de date a échoué');
     }
   })
   .catch((e) => console.log(e));
},
async fetchIntervalActivities({ commit, dispatch }, historyData) {
 //console.log(historyData);

 const requestOptions = {
         method: "PUT",
         headers: {  Authorization:
           "Bearer " + JSON.parse(localStorage.getItem("token")),
           "Content-Type": "application/json" },
         body: JSON.stringify({
           date: historyData.date,
           compOrigin : historyData.compOrigin,
           username : historyData.username,
           user_id: historyData.user_id,
         }),
       };

 let response = await fetch(
   appPath+"/activities/interval_activities",
   requestOptions
 );
 if (!response.ok) {
   // get error message from body or default to response status
   const error = (data && data.message) || response.status;
   //console.log('not response ok, error : ' + error);
   alert("une erreur innattendue s'est produite");
   return Promise.reject(error);
 }
 return await response.json();
},
//get history for user part
getConstructionsHistoryResume: ({ commit, dispatch }, userInfos) => {
  //dans ce cas c'est la requete admin, plus simple sans l'histo
  if(userInfos.includes('construction-nb-Admin')){
    dispatch("fetchConstructionsHistoryResume", userInfos)
    .then((data) => {
      if(data['data']){
        //console.log('fetchConstructionsResumeAdmin');
        //console.log(data['data'])
      }
      else{
        alert('requete impossible');
      }
      // pas d'username, on fitre plutot par chantier
      
        commit("constructionsResumeAdmin", data["data"]);
        return
    })
    .catch((e) => console.log(e));
  }

  dispatch("fetchConstructionsHistoryResume", userInfos)
  .then((data) => {
    if(data['data']){
      //console.log('fetchConstructionsResume');
      //console.log(data['data'])
    }
    else{
      alert('requete impossible');
    }
    // pas d'username, on fitre plutot par chantier
    if(userInfos.split('_')[0].includes('construction-nb:')){
      commit("constructionsResumeHistoryGlobal", data["data"]);
      return
    }
  commit("constructionsResumeHistory", data["data"]);
  })
  .catch((e) => console.log(e));
},
async fetchConstructionsHistoryResume({ commit, dispatch },  userInfos) {
  let response = await fetch(appPath+"/activities/constructions_history_resume/" + userInfos, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
getActivitiesHistory : ({ commit, dispatch }, userInfos) => {
  dispatch("fetchGetActivitiesHistory", userInfos)
  .then((data) => {
    if(data['data']){
      //console.log('getActivitiesHistory');
      //console.log(data['data'])
    }
    else{
      alert('requete impossible');
    }
    commit("activitiesHistory", data["data"]);
  })
  .catch((e) => console.log(e));
},
async fetchGetActivitiesHistory({ commit, dispatch },  userInfos) {
  let response = await fetch(appPath+"/activities/activities_history/" + userInfos, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
//--------------SERVICES CLIENTS -------------------
getServices: ({ commit, dispatch }, userInfos) => {
  dispatch("fetchGetServices", userInfos)
  .then((data) => {
    if(data['data']){
      //console.log('getServices');
      //console.log(data['data'])
    }
    else{
      alert('requete impossible');
    }
    commit("servicesClients", data["data"]);
    commit("dataFetched2");
  })
  .catch((e) => console.log(e));
},
async fetchGetServices({ commit, dispatch },  userInfos) {
  let response = await fetch(appPath+"/activities/get_services/" + userInfos, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
getClientFromServices: ({ commit, dispatch }, userInfos) => {
  dispatch("fetchGetClientFromServices", userInfos)
  .then((data) => {
    if(data['data']){
      //console.log('getClientFromServices');
      //console.log(data['data'])
    }
    else{
      alert('requete impossible');
    }
    commit("clientFromServices", data["data"]);
  })
  .catch((e) => console.log(e));
},
async fetchGetClientFromServices({ commit, dispatch },  userInfos) {
  let response = await fetch(appPath+"/activities/get_client_from_services/" + userInfos, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
getClientFromConstructions: ({ commit, dispatch }, userInfos) => {
  dispatch("fetchGetClientFromConstructions", userInfos)
  .then((data) => {
    if(data['data']){
      //console.log('getClientFromConstructions');
      //console.log(data['data'])
    }
    else{
      alert('requete impossible');
    }
    commit("clientFromConstructions", data["data"]);
  })
  .catch((e) => console.log(e));
},
async fetchGetClientFromConstructions({ commit, dispatch },  userInfos) {
  let response = await fetch(appPath+"/activities/get_client_from_constructions/" + userInfos, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
getDocumentsInfos: ({ commit, dispatch }, userInfos) => {
  dispatch("fetchGetDocumentsInfos", userInfos)
  .then((data) => {
    if(data['data']){
      //console.log('getDocumentsInfos');
      //console.log(data['data'])
    }
    else{
      alert('requete impossible');
    }
    commit("documentsInfos", data["data"]);
  })
  .catch((e) => console.log(e));
},
async fetchGetDocumentsInfos({ commit, dispatch },  userInfos) {
  let response = await fetch(appPath+"/activities/get_documents_infos/" + userInfos, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},
getDocumentsData: ({ commit, dispatch }, userInfos) => {
  dispatch("fetchGetDocumentsData", userInfos)
  .then((data) => {
    if(data['data']){
      //console.log('getDocumentsData');
      //console.log(data['data'])
    }
    else{
      alert('requete impossible');
    }
    commit("documentsData", data["data"]);
  })
  .catch((e) => console.log(e));
},
async fetchGetDocumentsData({ commit, dispatch },  userInfos) {
  let response = await fetch(appPath+"/activities/get_documents_data/" + userInfos, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
},

},
  modules: {
  }
})
