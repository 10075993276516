import { createRouter, createWebHashHistory } from 'vue-router'
import Auth from "../views/Auth.vue";

import CryptoJS from 'crypto-js'

const key = '82f2ceed4c503896c8a291e560bd4325' // change to your key
const iv = 'sinasinasisinaaa' // change to your iv

function aesEncrypt(txt) {
  const cipher = CryptoJS.AES.encrypt(txt, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC
  })

  return cipher.toString()
};
function aesDencrypt(txt) {
  const cipher = CryptoJS.AES.decrypt(txt, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC
  })

  return CryptoJS.enc.Utf8.stringify(cipher).toString()
};

function isKeyExist(obj, key) {
  return obj.hasOwnProperty(key);
}

const routes = [
  {
    path: '/',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/Home',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    beforeEnter(to, from, next){
      if(isKeyExist(localStorage, "LSSECKOCAC")){
      //console.log('from local : ',JSON.parse(window.sessionStorage.LSSECKOCAC));
      let LSSECKOCAC = JSON.parse(window.localStorage.LSSECKOCAC);
      let LSSECKOCACCrypted = JSON.parse(window.localStorage.LSSECKOCACCrypted);
      const LSSECKOCACDecrypted = aesDencrypt(LSSECKOCACCrypted);
      //console.log(LSSECKOCAC);
      //console.log(LSSECKOCACCrypted);
      //console.log(LSSECKOCACDecrypted);
      if(LSSECKOCAC && 
        (LSSECKOCAC === LSSECKOCACDecrypted) ){
          //console.log('true');
        next();
        }
        else {
          //console.log('false');
          next("/403")
        }}
      else {
        //console.log('false');
        next("/403")
      }
    }
  },
  {
    path: '/AddItems',
    name: 'AddItems',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AddItems.vue'),
    beforeEnter(to, from, next){
      if(isKeyExist(localStorage, "LSSECKOCAC")){
      //console.log('from local : ',JSON.parse(window.sessionStorage.LSSECKOCAC));
      let LSSECKOCAC = JSON.parse(window.localStorage.LSSECKOCAC);
      let LSSECKOCACCrypted = JSON.parse(window.localStorage.LSSECKOCACCrypted);
      const LSSECKOCACDecrypted = aesDencrypt(LSSECKOCACCrypted);
      //console.log(LSSECKOCAC);
      //console.log(LSSECKOCACCrypted);
      //console.log(LSSECKOCACDecrypted);
      if(LSSECKOCAC && 
        (LSSECKOCAC === LSSECKOCACDecrypted) ){
          //console.log('true');
        next();
        }
        else {
          //console.log('false');
          next("/403")
        }}
      else {
        //console.log('false');
        next("/403")
      }
    }
  },
  {
    path: '/Activity',
    name: 'Activity',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Activity.vue'),
    beforeEnter(to, from, next){
      if(isKeyExist(localStorage, "LSSECKOCAC")){
      //console.log('from local : ',JSON.parse(window.sessionStorage.LSSECKOCAC));
      let LSSECKOCAC = JSON.parse(window.localStorage.LSSECKOCAC);
      let LSSECKOCACCrypted = JSON.parse(window.localStorage.LSSECKOCACCrypted);
      const LSSECKOCACDecrypted = aesDencrypt(LSSECKOCACCrypted);
      //console.log(LSSECKOCAC);
      //console.log(LSSECKOCACCrypted);
      //console.log(LSSECKOCACDecrypted);
      if(LSSECKOCAC && 
        (LSSECKOCAC === LSSECKOCACDecrypted) ){
          //console.log('true');
        next();
        }
        else {
          //console.log('false');
          next("/403")
        }}
      else {
        //console.log('false');
        next("/403")
      }
    }
  },
  {
    path: '/Items',
    name: 'Items',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Items.vue'),
    beforeEnter(to, from, next){
      if(isKeyExist(localStorage, "LSSECKOCAC")){
      //console.log('from local : ',JSON.parse(window.sessionStorage.LSSECKOCAC));
      let LSSECKOCAC = JSON.parse(window.localStorage.LSSECKOCAC);
      let LSSECKOCACCrypted = JSON.parse(window.localStorage.LSSECKOCACCrypted);
      const LSSECKOCACDecrypted = aesDencrypt(LSSECKOCACCrypted);
      //console.log(LSSECKOCAC);
      //console.log(LSSECKOCACCrypted);
      //console.log(LSSECKOCACDecrypted);
      if(LSSECKOCAC && 
        (LSSECKOCAC === LSSECKOCACDecrypted) ){
          //console.log('true');
        next();
        }
        else {
          //console.log('false');
          next("/403")
        }}
      else {
        //console.log('false');
        next("/403")
      }
    }
  },
  {
    path: '/Basket',
    name: 'Basket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basket.vue'),
    beforeEnter(to, from, next){
      if(isKeyExist(localStorage, "LSSECKOCAC")){
      //console.log('from local : ',JSON.parse(window.sessionStorage.LSSECKOCAC));
      let LSSECKOCAC = JSON.parse(window.localStorage.LSSECKOCAC);
      let LSSECKOCACCrypted = JSON.parse(window.localStorage.LSSECKOCACCrypted);
      const LSSECKOCACDecrypted = aesDencrypt(LSSECKOCACCrypted);
      //console.log(LSSECKOCAC);
      //console.log(LSSECKOCACCrypted);
      //console.log(LSSECKOCACDecrypted);
      if(LSSECKOCAC && 
        (LSSECKOCAC === LSSECKOCACDecrypted) ){
          //console.log('true');
        next();
        }
        else {
          //console.log('false');
          next("/403")
        }}
      else {
        //console.log('false');
        next("/403")
      }
    }
  },
  {
    path: '/MyOrders',
    name: 'MyOrders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MyOrders.vue'),
    beforeEnter(to, from, next){
      if(isKeyExist(localStorage, "LSSECKOCAC")){
      //console.log('from local : ',JSON.parse(window.sessionStorage.LSSECKOCAC));
      let LSSECKOCAC = JSON.parse(window.localStorage.LSSECKOCAC);
      let LSSECKOCACCrypted = JSON.parse(window.localStorage.LSSECKOCACCrypted);
      const LSSECKOCACDecrypted = aesDencrypt(LSSECKOCACCrypted);
      //console.log(LSSECKOCAC);
      //console.log(LSSECKOCACCrypted);
      //console.log(LSSECKOCACDecrypted);
      if(LSSECKOCAC && 
        (LSSECKOCAC === LSSECKOCACDecrypted) ){
          //console.log('true');
        next();
        }
        else {
          //console.log('false');
          next("/403")
        }}
      else {
        //console.log('false');
        next("/403")
      }
    }
  },
  {
    path: '/MyConstructions',
    name: 'MyConstructions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MyConstructions.vue'),
    beforeEnter(to, from, next){
      if(isKeyExist(localStorage, "LSSECKOCAC")){
      //console.log('from local : ',JSON.parse(window.sessionStorage.LSSECKOCAC));
      let LSSECKOCAC = JSON.parse(window.localStorage.LSSECKOCAC);
      let LSSECKOCACCrypted = JSON.parse(window.localStorage.LSSECKOCACCrypted);
      const LSSECKOCACDecrypted = aesDencrypt(LSSECKOCACCrypted);
      //console.log(LSSECKOCAC);
      //console.log(LSSECKOCACCrypted);
      //console.log(LSSECKOCACDecrypted);
      if(LSSECKOCAC && 
        (LSSECKOCAC === LSSECKOCACDecrypted) ){
          //console.log('true');
        next();
        }
        else {
          //console.log('false');
          next("/403")
        }}
      else {
        //console.log('false');
        next("/403")
      }
    }
  },
  {
    path: '/CreateOrder',
    name: 'CreateOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateOrder.vue'),
    beforeEnter(to, from, next){
      if(isKeyExist(localStorage, "LSSECKOCAC")){
      //console.log('from local : ',JSON.parse(window.sessionStorage.LSSECKOCAC));
      let LSSECKOCAC = JSON.parse(window.localStorage.LSSECKOCAC);
      let LSSECKOCACCrypted = JSON.parse(window.localStorage.LSSECKOCACCrypted);
      const LSSECKOCACDecrypted = aesDencrypt(LSSECKOCACCrypted);
      //console.log(LSSECKOCAC);
      //console.log(LSSECKOCACCrypted);
      //console.log(LSSECKOCACDecrypted);
      if(LSSECKOCAC && 
        (LSSECKOCAC === LSSECKOCACDecrypted) ){
          //console.log('true');
        next();
        }
        else {
          //console.log('false');
          next("/403")
        }}
      else {
        //console.log('false');
        next("/403")
      }
    }
  },
  {
    path: '/AdminOrders',
    name: 'AdminOrders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminOrders.vue'),
    beforeEnter(to, from, next){
      if(isKeyExist(localStorage, "LSSECKOCAC")){
      //console.log('from local : ',JSON.parse(window.sessionStorage.LSSECKOCAC));
      let LSSECKOCAC = JSON.parse(window.localStorage.LSSECKOCAC);
      let LSSECKOCACCrypted = JSON.parse(window.localStorage.LSSECKOCACCrypted);
      const LSSECKOCACDecrypted = aesDencrypt(LSSECKOCACCrypted);
      //console.log(LSSECKOCAC);
      //console.log(LSSECKOCACCrypted);
      //console.log(LSSECKOCACDecrypted);
      if(LSSECKOCAC && 
        (LSSECKOCAC === LSSECKOCACDecrypted) ){
          //console.log('true');
        next();
        }
        else {
          //console.log('false');
          next("/403")
        }}
      else {
        //console.log('false');
        next("/403")
      }
    }
  },
  {
    path: '/History',
    name: 'History',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/History.vue'),
    beforeEnter(to, from, next){
      if(isKeyExist(localStorage, "LSSECKOCAC")){
      //console.log('from local : ',JSON.parse(window.sessionStorage.LSSECKOCAC));
      let LSSECKOCAC = JSON.parse(window.localStorage.LSSECKOCAC);
      let LSSECKOCACCrypted = JSON.parse(window.localStorage.LSSECKOCACCrypted);
      const LSSECKOCACDecrypted = aesDencrypt(LSSECKOCACCrypted);
      //console.log(LSSECKOCAC);
      //console.log(LSSECKOCACCrypted);
      //console.log(LSSECKOCACDecrypted);
      if(LSSECKOCAC && 
        (LSSECKOCAC === LSSECKOCACDecrypted) ){
          //console.log('true');
        next();
        }
        else {
          //console.log('false');
          next("/403")
        }}
      else {
        //console.log('false');
        next("/403")
      }
    }
  },
  {
    path: '/Report',
    name: 'ReportErr',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportErr.vue'),
    beforeEnter(to, from, next){
      if(isKeyExist(localStorage, "LSSECKOCAC")){
      //console.log('from local : ',JSON.parse(window.sessionStorage.LSSECKOCAC));
      let LSSECKOCAC = JSON.parse(window.localStorage.LSSECKOCAC);
      let LSSECKOCACCrypted = JSON.parse(window.localStorage.LSSECKOCACCrypted);
      const LSSECKOCACDecrypted = aesDencrypt(LSSECKOCACCrypted);
      //console.log(LSSECKOCAC);
      //console.log(LSSECKOCACCrypted);
      //console.log(LSSECKOCACDecrypted);
      if(LSSECKOCAC && 
        (LSSECKOCAC === LSSECKOCACDecrypted) ){
         // console.log('true');
        next();
        }
        else {
         // console.log('false');
          next("/403")
        }}
      else {
       // console.log('false');
        next("/403")
      }
    }
  },
  {
    path: "/403",
    name: "Notauthorized",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Notauthorized.vue"),
  },
  {
    path: "/404",
    name: "Notfound",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Notfound.vue"),
  },
  {
    path: "/Offline",
    name: "Offline",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Offline.vue"),
  },
  { 
    path: "/:catchAll(.*)",
    name : 'Redirection', 
    redirect: '/404' 
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
