<template>
<div id ="main" ref="main">
  <div v-if="isConnected" id="nav">
    
    <!--@mouseover="seeNav()" @blur="hideNav()"!-->
    <button 
    @mouseover="seeNav()" @blur="hideNav('blur')" @click.stop="hideNav('click')"
    :style="usingMode && usingModeChecking ? {'backgroundColor' : 'red'}:{}"
    ref="navButton" id="navButton">&#9776;  </button>
    <div class="showM" ref="showM"  >
    <ul class="app-ul" >
    <li v-if="user['a5e789410fd'] === this.rand && !this.usingModeChecking"><i class="fas fa-lock"></i><router-link to="/AdminOrders">Réceptions<span class="icon">&ltrif;</span></router-link></li>
    <li v-if="user['a5e789410fd'] === this.rand && !this.usingModeChecking"><i class="fas fa-lock"></i><router-link to="/CreateOrder">Alertes & BC <span class="icon">&ltrif;</span></router-link></li>
    <li v-if="user['a5e789410fd'] === this.rand && !this.usingModeChecking"><i class="fas fa-lock"></i><router-link to="/AddItems">Gestion<span class="icon">&ltrif;</span></router-link></li>
    <li v-if="user['a5e789410fd'] === this.rand && !this.usingModeChecking"><i class="fas fa-lock"></i><router-link to="/Activity">Activité<span class="icon">&ltrif;</span></router-link></li>
    <li v-if="user['a5e789410fd'] === this.rand && !this.usingModeChecking"><i class="fas fa-lock"></i><router-link to="/Home">Options <span class="icon">&ltrif;</span></router-link></li>
    <li v-if="user['a5e789410fd'] === this.rand && !this.usingModeChecking"><i class="fas fa-lock"></i><router-link to="/History">Historique <span class="icon">&ltrif;</span></router-link></li>
    <li v-if="user['a5e789410fd'] === this.rand && !this.usingModeChecking"><i class="fas fa-lock"></i><router-link to="/Report">Rapport <span class="icon">&ltrif;</span></router-link></li>
    <!--<li><router-link to="/">Authentification<span class="icon">&ltrif;</span></router-link></li>!-->
    <li><router-link to="/Items">Stock<span class="icon">&ltrif;</span></router-link></li>
    <li><router-link to="/Basket">Panier<span class="icon">&ltrif;</span></router-link></li>
    <li><router-link to="/MyOrders">Mes commandes<span class="icon">&ltrif;</span></router-link></li>
    <li><router-link to="/MyConstructions">Chantier<span class="icon">&ltrif;</span></router-link></li>  
</ul></div>
  </div>
  <router-view/>
</div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data : function(){return{
    fullWidth: document.documentElement.clientWidth,
    rand : '01',
    user : {},
    usingModeChecking : false
  }},
  mounted () {
    //permet de verifier au refresh
    this.usingModeChecking = localStorage.getItem('usingMode');
    },
  computed:{
    ...mapState({
      isConnected : "isConnected",
      usingMode :"usingMode",
      exitUsingMode : "exitUsingMode",
    }),
  },
  watch: {
    isConnected(newCount, oldCount) {
      if(newCount ===true){
        //console.log('newcount');
        if(JSON.parse(localStorage.getItem('connectedUser'))){
    this.user = JSON.parse(localStorage.getItem('connectedUser'));}
    else{
      this.user = {};
    }
    this.rand = '2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16'+JSON.parse(localStorage.getItem('token'))+'1202'+JSON.parse(localStorage.getItem('token')).substr(12, 42);
      }
    },
    usingMode(newCount, oldCount) {
      if(newCount === true){
        localStorage.setItem("usingMode", true);
        this.usingModeChecking = true;
        this.$router.push('/MyOrders')
      }
    },
    exitUsingMode(newCount, oldCount) {
      if(newCount === true){
        localStorage.removeItem('usingModeU');
      localStorage.removeItem('usingModeI');
      localStorage.removeItem('usingModeN');
      localStorage.removeItem("usingMode");
      this.$store.state.usingMode = false;
      this.usingModeChecking = false
      this.$router.push('/Home');
      }
    },
    t_Key(newCount, oldCount) {
      if(newCount === 'unlocked'){
        if(JSON.parse(localStorage.getItem('connectedUser'))){
          const user = JSON.parse(localStorage.getItem('connectedUser'));
          if(user.admin === true){
              //QUITTER LA CONNEXION
          alert('ACCES REFUSE :1');
          this.$router.push('/');
          }
        }
      }
      else if(newCount != 'unlocked'){
        if(JSON.parse(localStorage.getItem('t_key'))){
            const T_KEY = JSON.parse(localStorage.getItem('t_key'));
            const strKey = newCount.split('_')[0];
            const timing = newCount.split('_')[1];
            if(T_KEY === strKey){
              var timestampSecond = Math.floor(Date.now() / 1000);
              if(timestampSecond > timing + 28800){
                alert("Connecté depuis + de 8h, SESSION EXPIREE");
                //QUITTER LA CONNEXION
                this.$router.push('/')
              }
              //console.log('ACCES VERIFIE OK')
            }
            else {
              alert('ACCES REFUSE :3');
               //QUITTER LA CONNEXION
               this.$router.push('/')
            }
      }
      else {
        alert('ACCES REFUSE :2');
        //QUITTER LA CONNEXION
        this.$router.push('/')
      }
      }
    },
   
  },
  methods: {
    seeNav(){
      //this.menuDeroulant = true;
      this.$refs['navButton'].focus();
      this.$refs['showM'].style.transform = 'scaleY(1.0)';
      this.$refs['showM'].style.opacity = '1';
    },
    hideNav(type){
      //console.log(window.innerWidth);
      if(window.innerWidth < 1000 && type === 'blur'){
      setTimeout(() => {
        this.$refs['showM'].style.opacity = '0.3';
      this.$refs['showM'].style.transform = 'scaleY(0)';
      }, 200);
      }
      else if(window.innerWidth >= 1000){
      setTimeout(() => {
        this.$refs['showM'].style.opacity = '0.3';
      this.$refs['showM'].style.transform = 'scaleY(0)';
      }, 200);
      }
    },
  }
}
    </script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

button {
    cursor: pointer;
  }
#navButton{
  font-size: 25px;
  line-height: 5px;
  width: 200px;
  height: 35px;
  border-color: #7c7c7c;
  -webkit-box-shadow: 3px 3px 5px -2px #000000; 
  box-shadow: 3px 3px 5px -2px #000000;
  background: linear-gradient(top, rgba(38, 38, 38, 0.8), #e6e6e6 25%, #ffffff 38%, #c5c5c5 63%, #f7f7f7 87%, rgba(38, 38, 38, 0.8));
  background: -webkit-linear-gradient(top, rgba(38, 38, 38, 0.5), #e6e6e6 25%, #ffffff 38%, rgba(0, 0, 0, 0.25)  63%, #e6e6e6 87%, rgba(38, 38, 38, 0.4));
&:hover{
  background-color : greenyellow;
}
}
    .showM {
    //display: none;
    position: absolute;
    width: 99%;
    opacity : 0.3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color:transparent;
    z-index : 5;
    transition: transform .4s cubic-bezier(.12,.67,.56,.36),opacity .25s cubic-bezier(.46,.59,0,1.02);
    transform-origin: top;
    transform: scaleY(0);
    }
#nav {
  z-index: 4;
  //height: 30px;
  //width: 80px;
  //background-color: green;
  

  ul{
    padding: 20px;
      //border: 1px solid black;
      box-shadow: 0px 7px 21px 2px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 7px 21px 2px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 7px 21px 2px rgba(0,0,0,0.75);
      background-color:rgb(255, 255, 255);
      border: 1px solid black;
      list-style-type: none;
      width: 250px;
      li {
       position: relative;
  padding-bottom : 5px;
  font-size: 28px;
  background-color:rgba(212, 212, 212,0);
  &:hover{
    //background-color:rgb(212, 212, 212);
     background: linear-gradient(top, rgba(38, 38, 38, 0.8), #e6e6e6 25%, #ffffff 38%, #c5c5c5 63%, #f7f7f7 87%, rgba(38, 38, 38, 0.8));
  background: -webkit-linear-gradient(top, rgba(38, 38, 38, 0.5), #e6e6e6 25%, #ffffff 38%, rgba(0, 0, 0, 0.25)  63%, #e6e6e6 87%, rgba(38, 38, 38, 0.4));
    background-color : greenyellow;
  }

  a {
    //position: absolute;
    //font-weight: bold;
    color: black;
    opacity: 0.6;
    text-decoration: none;
    &:hover{
      opacity: 1;
    }
    &.router-link-exact-active .icon{
      display : inline;
    }
    
  }}}
}
.icon {
  display : none;
}
.fa-lock{
font-size : 12px;
position: absolute;
left : 5px;
top: 5px;
color: rgba(128, 128, 128,0.5);
}
@media screen and (max-width: 1100px) {
  #navButton{
  font-size: 20px;
  line-height: 5px;
  width: 70px;
  margin: 20px;
  height: 25px;
}
.showM {
 
    transition: transform .15s cubic-bezier(.12,.67,.56,.36),opacity .15s cubic-bezier(.46,.59,0,1.02);
    }
}

</style>
